<template>
  <div class="hello">
    <h3>{{ link.text }}</h3>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    api: "https://api.monorain.net",
    linkId: null,
    link: {
      text: "Loading...",
      link: ""
    }
  }),
  created() {
    this.linkId = window.location.pathname.replace('/', '')

    this.init()
  },
  methods: {
    init() {

      this.axios({
        url: `${this.api}/link/${this.linkId}`,
        method: "GET"
      })
      .then((res) => {
          this.link = res.data
          window.location.replace(this.link.link)
      })
      .catch(() => {
          this.link.text = "해당 링크가 없습니다."
      });
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

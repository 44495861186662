import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { 
    path: '/link', 
    name: 'link',
    props: true,
    component:  import('@/views/LinkMove')
  }
]

// 라우터 생성
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes
});

export default router;